import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from "models/shared/index";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { history } from "utils/history";
import { IClinic, IClinicForm, IClinicNfse, IClinicNfseForm } from "models/Clinics";

interface IInitialState {
  isFetchingClinics: boolean;
  isCreatingClinic: boolean;
  isDeletingClinic: boolean;
  clinics: IClinic[] | null;
  currentClinic: IClinic | null;
  currentClinicNfse: IClinicNfse | null;
  total: number;
  page: number;
  filterArray: IFilter[];
}

const originalFilterArray: IFilter[] = [
  { key: "nome", value: [] },
  { key: "ativo", value: null },
];

const initialState: IInitialState = {
  isFetchingClinics: false,
  isCreatingClinic: false,
  isDeletingClinic: false,
  clinics: null,
  currentClinic: null,
  currentClinicNfse: null,
  total: 0,
  page: 0,
  filterArray: [{ key: "nome", value: [] }],
};

const clinicsSlice = createSlice({
  name: "clinicsSlice",
  initialState,
  reducers: {
    setisFetchingClinics: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingClinics = payload;
    },
    setisCreatingClinic: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingClinic = payload;
    },
    setisDeletingClinic: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingClinic = payload;
    },
    setClinics: (
      state,
      {
        payload: { clinics, total, page },
      }: PayloadAction<{ clinics: IClinic[]; total: number; page: number }>
    ) => {
      state.clinics = clinics;
      state.total = total;
      state.page = page;
    },
    setCurrentClinic: (state, { payload }: PayloadAction<IClinic>) => {
      state.currentClinic = payload;
    },
    setCurrentClinicNfse: (state, { payload }: PayloadAction<IClinicNfse>) => {
      state.currentClinicNfse = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.filterArray.push({ key: payload.key, value: payload.value });
      } else {
        state.filterArray[index].value = payload.value;
      }
    },
    resetClinicsFilterArray: (state, { payload }: PayloadAction<IFilter>) => {
      state.filterArray = originalFilterArray;
    },
  },
});

export const fetchClinics =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setisFetchingClinics, setClinics } = clinicsSlice.actions;
    dispatch(setisFetchingClinics(true));
    try {
      const state = getState();
      const { filterArray } = state.clinics;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/clinics${queryParameters}${pageAndLimit}`
      );
      dispatch(setClinics(response.data.data));
      dispatch(setisFetchingClinics(false));
    } catch (error: any) {
      dispatch(setisFetchingClinics(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchClinicById =
  ({ clinicId }: { clinicId: string }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingClinics, setCurrentClinic } = clinicsSlice.actions;
    dispatch(setisFetchingClinics(true));
    try {
      const response = await api.get(`/api/clinics/${clinicId}`);
      dispatch(setCurrentClinic(response.data.data));
      dispatch(setisFetchingClinics(false));
    } catch (error: any) {
      dispatch(setisFetchingClinics(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createClinic =
  (clinic: IClinicForm): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      await api.post(`/api/clinics`, clinic);
      dispatch(setisCreatingClinic(false));
      toast.success("Clínica cadastrada", toastOptions);
      history.replace("/clinics");
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
        console.log(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const createClinicNfse =
  (clinic: IClinicNfseForm, idclinica: string): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      await api.post(`/api/clinics/${idclinica}/nfse`, clinic);
      dispatch(setisCreatingClinic(false));
      toast.success("Dados de NFS-e cadastrada", toastOptions);
      history.replace("/clinics");
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
        console.log(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const findClinicNfseById =
  ({
    idclinicanfse,
    idclinica,
  }: {
    idclinicanfse: string;
    idclinica: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic, setCurrentClinicNfse } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      const res = await api.get(`/api/clinics/${idclinica}/nfse/${idclinicanfse}`);
      dispatch(setisCreatingClinic(false));
      dispatch(setCurrentClinicNfse(res.data.data));
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
        console.log(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateClinicNfse =
  ({
    clinicNfse,
    clinicId,
    clinicNfseId,
  }: {
    clinicNfse: IClinicNfseForm;
    clinicNfseId: string;
    clinicId: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      await api.put(`/api/clinics/${clinicId}/nfse/${clinicNfseId}`, clinicNfse);
      dispatch(setisCreatingClinic(false));
      toast.success("Dados de Nota Fiscal atualizados", toastOptions);
      history.replace("/clinics");
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const updateClinic =
  ({
    clinic,
    clinicId,
  }: {
    clinic: IClinicForm;
    clinicId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      await api.put(`/api/clinics/${clinicId}`, clinic);
      dispatch(setisCreatingClinic(false));
      toast.success("Clínica atualizada", toastOptions);
      history.replace("/clinics");
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const clinicActivation =
  ({
    isClinicActive,
    clinicId,
  }: {
    isClinicActive: boolean;
    clinicId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setisCreatingClinic } = clinicsSlice.actions;
    dispatch(setisCreatingClinic(true));
    try {
      await api.put(`/api/clinics/${clinicId}`, { ativo: !isClinicActive });
      dispatch(setisCreatingClinic(false));
      toast.success(
        `Clinica ${isClinicActive ? "desativada" : "ativada"} com sucesso`,
        toastOptions
      );
      dispatch(fetchClinics({}));
      // history.replace("/clinics");
    } catch (error: any) {
      dispatch(setisCreatingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteClinic =
  (clinicId: string): AppThunk =>
  async (dispatch) => {
    const { setisDeletingClinic } = clinicsSlice.actions;
    dispatch(setisDeletingClinic(true));
    try {
      await api.delete(`/api/clinics/${clinicId}`);
      dispatch(setisDeletingClinic(false));
      toast.success("Clínica excluida com sucesso", toastOptions);
      dispatch(fetchClinics({}));
    } catch (error: any) {
      dispatch(setisDeletingClinic(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  updateFilter: updateClinicsFilter,
  resetClinicsFilterArray,
  setCurrentClinic,
} = clinicsSlice.actions;

export default clinicsSlice.reducer;
