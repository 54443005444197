import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IOrcamento } from "models/CustomerQuote";
import { IFilter } from "models/shared/index";
import {
  CustomerQuoteItems,
  ICustomerQuoteReportData,
  ICustomerQuoteReportsResultsTable,
} from "pages/Reports/CustomerQuotes/CustomerQuotesResultsTable/CustomerQuoteReportsResultsTable.types";
import { toast } from "react-toastify";
import { AppThunk } from "store/index";
import api, { apiNf } from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

import dayjs from "dayjs";
import { IClinicNfse } from "models/Clinics";
import { IPaymentRows } from "pages/Reports/ReceivedPayments/ReceivedPaymentsTable/data.types";
import { cityOptions } from "utils/cityOptions";
import { formatCurrency } from "utils/formatCurrency";
import { IHealthPlansServiceResultsDTO } from "./ReportsReducer.types";
// import { HealthPlan } from "../HealthPlanReducer/HealthPlanReducer.types";
export interface IReceivedPayment {
  nomepaciente: string;
}

interface ICustomerQuoteObject extends IOrcamento {
  quitador: string;
  modificou: string;
}

export interface ICollaboratorResult {
  type: string;
  value: number;
  percent: number;
}

export interface IHealthPlanRank {
  health_plan_service_order_results: {
    os_ids: string[];
    health_plan: string;
    amount_health_plan_billed: number;
    amount_transfer_health_plan: number;
    amount_transfer_client: number;
    amount_paid: number;
  }[];

  analytic_service_order_results: {
    amount_discount: string[];
    amount_paid: string;
    base_cost: number;
    direct_costs: number;
    health_plan: string;
    item_description: number;
    os_number: number;
    paid_date: Date;
    patient_name: string;
    professional_name: string;
    plan_pass: number;
  }[];
}

export interface IRankingCollaborator {
  ordersItens: any[];
  groupByRoleResponse: {
    role: string;
    total_amount_billed: number;
    total_amount_plan: number;
    total_appointments: number;
    total_orders: number;
    tipo_visualizacao?: string;
    data: {
      user_id: string;
      name: string;
      relatoriocolaboradorvisualizacao?: boolean;
      role: string;
      appointments_amount: number;
      amount_billed: number;
      amount_plan: number;
      os_ids: string[];
      combo_ids: string[];
      serviceorders_amount: number;
      combos_amount: number;
      preorder: any[];
      appointments: ICollaboratorResult[];
      payments: ICollaboratorResult[];
      discounts_amount: number;
      customer_quotes_amount_done: number;
      customer_quotes_amount: number;
      medium_ticket: number;
      customer_quotes_percent: number;
      customer_quotes_percent_done: number;
      total_amount_billed: number;
      patients_amount: number;
    }[];
  }[];
  total_all_appointmens: number;
  total_all_groups: number;
  total_all_groups_plan: number;
  total_all_orders: number;
  appointment_schedule_by: string;
  view_type: string;
}
interface IInitialState {
  isFetchingReport: boolean;
  isFetchingCsvReport: boolean;
  isFetchingNF: boolean;
  showNfseDialog: boolean;
  showNfseByPaymentTypesDialog: boolean;
  isProcessingNfseDialog: boolean;
  receivedPaymentsByPaymentTypes: IPaymentRows[];
  receivedPayments: any | null;
  rankServices: any | null;
  rankHealthPlan: IHealthPlanRank | null;
  rankCollaborators: IRankingCollaborator | null;
  selectedPaymentsRowsToNfse: IPaymentRows[];
  currentPaymentRow: IPaymentRows | null;
  receivedPaymentsFilterArray: IFilter[];
  serviceResultsFilterArray: IFilter[];
  healthPlansServiceResultsFilterArray: IFilter[];
  priceListFilterArray: IFilter[];
  total: number;
  priceList: any[] | null;
  serviceResults: any;
  healthPlansServiceResults: IHealthPlansServiceResultsDTO | null;
  appointmentByStatus: any;
  professionalPerformance: any;
  customerQuotesFilterArray: IFilter[];
  customerQuotesReports: ICustomerQuoteReportsResultsTable | null;
  customerQuotesReportsCsv: ICustomerQuoteReportData[];
  customerQuotePage: number;
  customerQuoteLimit: number;
  customerQuoteTotal: number;
}

const initialState: IInitialState = {
  isFetchingReport: false,
  isFetchingCsvReport: false,
  showNfseDialog: false,
  showNfseByPaymentTypesDialog: false,
  isFetchingNF: false,
  currentPaymentRow: null,
  isProcessingNfseDialog: false,
  receivedPayments: null,
  rankServices: null,
  rankHealthPlan: null,
  rankCollaborators: null,
  receivedPaymentsByPaymentTypes: [],
  selectedPaymentsRowsToNfse: [],
  receivedPaymentsFilterArray: [
    { key: "idclinica", value: [] },
    { key: "idcategoriaservico", value: [] },
    { key: "iditemservico", value: [] },
    { key: "idprofissional", value: [] },
    { key: "idconvenio", value: [] },
    { key: "status", value: null },
    { key: "idcanalatendimento", value: [] },
    { key: "idusuario", value: [] },
    { key: "idconfirmador", value: [] },
    { key: "paciente", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "status_invoice", value: null },
    { key: "doc_paciente", value: null },
    { key: "idpaciente", value: null },
    { key: "tipoconsulta", value: null },
    { key: "idcargo", value: null },
  ],
  serviceResultsFilterArray: [
    { key: "tipoconsulta", value: null },
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: [] },
    { key: "idprofissional", value: null },
    { key: "idconvenio", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "metodopagamento", value: null }
  ],
  healthPlansServiceResultsFilterArray: [
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: [] },
    { key: "idprofissional", value: null },
    { key: "idconvenio", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
  ],
  priceListFilterArray: [
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: [] },
    { key: "idprofissional", value: null },
    { key: "idconvenio", value: [] },
    { key: "tipoconsulta", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
  ],
  total: 0,
  priceList: null,
  serviceResults: null,
  healthPlansServiceResults: null,
  appointmentByStatus: null,
  professionalPerformance: null,
  customerQuotesReports: null,
  customerQuotesReportsCsv: [],
  customerQuotesFilterArray: [
    { key: "idclinica", value: [] },
    { key: "cargos", value: [] },
    { key: "paciente", value: null },
    { key: "codigo", value: [] },
    { key: "status", value: [] },
    { key: "usuario", value: [] },
    { key: "idusuario", value: [] },
    { key: "modificado", value: null },
    { key: "quitado", value: [] },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "changelogs", value: true },
    { key: "solicitante", value: null },
    // { key: "dataquitado", value: null },
    { key: "tipoconsulta", value: null },
    { key: "idvendedor", value: [] },
  ],
  customerQuoteLimit: 10,
  customerQuotePage: 1,
  customerQuoteTotal: 0,
};

const reportsReducer = createSlice({
  name: "reportsReducer",
  initialState,
  reducers: {
    setIsFetchingReportCsv: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingCsvReport = payload;
    },
    setIsFetchingReport: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingReport = payload;
    },
    setIsFetchingNF: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingNF = payload;
    },
    setIsProcessingNfseDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.isProcessingNfseDialog = payload;
    },
    setShowNfseDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.showNfseDialog = payload;
    },
    setShowNfseByPaymentTypesDialog: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showNfseByPaymentTypesDialog = payload;
    },
    setReceivedPayments: (
      state,
      {
        payload: { receivedPayments },
      }: PayloadAction<{
        receivedPayments: any;
      }>
    ) => {
      state.receivedPayments = receivedPayments;
    },
    setRankServices: (
      state,
      {
        payload: { rankServices },
      }: PayloadAction<{
        rankServices: any;
      }>
    ) => {
      state.rankServices = rankServices;
    },
    setRankHealthPlan: (
      state,
      {
        payload: { rankHealthPlan },
      }: PayloadAction<{
        rankHealthPlan: IHealthPlanRank;
      }>
    ) => {
      state.rankHealthPlan = rankHealthPlan;
    },
    setRankCollaborators: (
      state,
      {
        payload: { rankCollaborators },
      }: PayloadAction<{
        rankCollaborators: IRankingCollaborator;
      }>
    ) => {
      state.rankCollaborators = rankCollaborators;
    },
    setReceivedPaymentsByPaymentTypes: (
      state,
      {
        payload: { receivedPaymentsByPaymentTypes },
      }: PayloadAction<{
        receivedPaymentsByPaymentTypes: IPaymentRows[];
      }>
    ) => {
      state.receivedPaymentsByPaymentTypes = receivedPaymentsByPaymentTypes;
    },
    setSelectedPaymentsRowsToNfse: (
      state,
      {
        payload: { selectedPaymentsRowsToNfse },
      }: PayloadAction<{
        selectedPaymentsRowsToNfse: IPaymentRows[];
      }>
    ) => {
      state.selectedPaymentsRowsToNfse = selectedPaymentsRowsToNfse;
    },
    setCurrentPaymentRow: (
      state,
      {
        payload: { currentPaymentRow },
      }: PayloadAction<{
        currentPaymentRow: IPaymentRows | null;
      }>
    ) => {
      state.currentPaymentRow = currentPaymentRow;
    },
    setPriceList: (
      state,
      {
        payload: { priceList },
      }: PayloadAction<{
        priceList: any[] | null;
      }>
    ) => {
      state.priceList = priceList;
    },
    setServiceResults: (
      state,
      {
        payload: { serviceResults },
      }: PayloadAction<{
        serviceResults: any;
      }>
    ) => {
      state.serviceResults = serviceResults;
    },
    setHealthPlansServiceResults: (
      state,
      {
        payload: { healthPlansServiceResults },
      }: PayloadAction<{
        healthPlansServiceResults: IHealthPlansServiceResultsDTO | null;
      }>
    ) => {
      state.healthPlansServiceResults = healthPlansServiceResults;
    },
    setAppointmentsByStatus: (
      state,
      {
        payload: { appointmentByStatus },
      }: PayloadAction<{
        appointmentByStatus: any;
      }>
    ) => {
      state.appointmentByStatus = appointmentByStatus;
    },
    setProfessionalPerformance: (
      state,
      {
        payload: { professionalPerformance },
      }: PayloadAction<{
        professionalPerformance: any;
      }>
    ) => {
      state.professionalPerformance = professionalPerformance;
    },
    setCustomerQuotesReports: (state, { payload }: PayloadAction<any>) => {
      state.customerQuotesReports = payload;
    },
    setCustomerQuotesReportsCsv: (
      state,
      { payload }: PayloadAction<ICustomerQuoteReportData[]>
    ) => {
      state.customerQuotesReportsCsv = payload;
    },
    updateReceivedPaymentsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      // console.log(key, value, state.receivedPaymentsFilterArray)
      state.receivedPaymentsFilterArray = state.receivedPaymentsFilterArray.map(
        (filter) => {
          console.log(`${key}${filter.key}`);
          if (filter.key === key) {
            filter.value = value;
            state.appointmentByStatus = null;
            state.rankServices = null;
            state.receivedPayments = null;
            state.rankCollaborators = null;
          }
          return filter;
        }
      );
    },
    updateClearReceivedPaymentsFilterArray: (state) => {
      state.receivedPaymentsFilterArray = [
        { key: "idclinica", value: null },
        { key: "idcategoriaservico", value: [] },
        { key: "iditemservico", value: [] },
        { key: "idprofissional", value: null },
        { key: "idconvenio", value: [] },
        { key: "status", value: null },
        { key: "idcanalatendimento", value: [] },
        { key: "idusuario", value: null },
        { key: "idconfirmador", value: null },
        { key: "paciente", value: null },
        { key: "start_date", value: null },
        { key: "end_date", value: null },
        { key: "status_invoice", value: null },
        { key: "doc_paciente", value: null },
        { key: "idpaciente", value: null },
        { key: "tipoconsulta", value: null },
        { key: "idcargo", value: null },
      ];
    },
    updateServiceResultsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.serviceResultsFilterArray = state.serviceResultsFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            state.serviceResults = null;
            filter.value = value;
          }
          return filter;
        }
      );
    },
    updateHealthPlansServiceResultsFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.healthPlansServiceResultsFilterArray = state.healthPlansServiceResultsFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            state.healthPlansServiceResults = null;
            filter.value = value;
          }
          return filter;
        }
      );
    },
    updatePriceListFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.priceListFilterArray = state.priceListFilterArray.map((filter) => {
        if (filter.key === key) {
          filter.value = value;
          state.priceList = null;
        }
        return filter;
      });
    },
    updateCustomerQuotesFilterArray: (
      state,
      { payload: { key, value } }: PayloadAction<IFilter>
    ) => {
      state.customerQuotesFilterArray = state.customerQuotesFilterArray.map(
        (filter) => {
          if (filter.key === key) {
            filter.value = value;
            state.customerQuotesReports = null;
          }
          return filter;
        }
      );
    },
    setCustomerQuotePage: (state, { payload }: PayloadAction<number>) => {
      state.customerQuotePage = payload;
    },
    setCustomerQuoteLimit: (state, { payload }: PayloadAction<number>) => {
      state.customerQuoteLimit = payload;
    },
    setCustomerQuoteTotal: (state, { payload }: PayloadAction<number>) => {
      state.customerQuoteTotal = payload;
    },
    updateClearFilter: (state, { payload }: PayloadAction<IFilter[]>) => {
      state.receivedPaymentsFilterArray = payload;
    },
    updateClearFilterServiceResults: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.serviceResultsFilterArray = payload;
    },
    updateClearFilterHealthPlansServiceResults: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.healthPlansServiceResultsFilterArray = payload;
    },
    updateClearFilterCustomerQuotes: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.customerQuotesFilterArray = payload;
    },
    updateClearFilterRankingServices: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.receivedPaymentsFilterArray = payload;
    },
    updateClearFilterPriceList: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      state.priceListFilterArray = payload;
    },
  },
});

export const fetchReceivedPayments =
  ({ page = 1, limit = 999 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setReceivedPayments } = reportsReducer.actions;
    dispatch(setIsFetchingReport(true));
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}&status=quitado`
        : `&page=${page}&limit=${limit}&status=quitado`;
    try {
      const response = await api.get(
        `/api/reports/receivedpayments${queryParameters}${pageAndLimit}`
      );
      dispatch(
        setReceivedPayments({ receivedPayments: response.data.data as any })
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchRankServices =
  ({ page = 1, limit = 999 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setRankServices } = reportsReducer.actions;
    dispatch(setIsFetchingReport(true));
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    try {
      const response = await api.get(
        `/api/reports/serviceranking${queryParameters}${pageAndLimit}`
      );
      dispatch(setRankServices({ rankServices: response.data.data as any }));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchRankHealthPlan =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setRankHealthPlan } = reportsReducer.actions;
    dispatch(setIsFetchingReport(true));
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );

    try {
      const response = await api.get(
        `/api/reports/health-plan-ranking${queryParameters}`
      );
      dispatch(
        setRankHealthPlan({ rankHealthPlan: response.data.data as any })
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchRankCollaborators =
  ({ page = 1, limit = 999 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setRankCollaborators } =
      reportsReducer.actions;
    dispatch(setIsFetchingReport(true));
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );
    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;
    try {
      const response = await api.get(
        `/api/reports/users-performance${queryParameters}${pageAndLimit}`
      );
      dispatch(setRankCollaborators({ rankCollaborators: response.data.data }));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchReceivedPaymentsByPaymentTypes =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setReceivedPaymentsByPaymentTypes } =
      reportsReducer.actions;
    dispatch(setIsFetchingReport(true));
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );
    try {
      const response = await api.get(
        `/api/reports/receivedpayments/payment-types${queryParameters}`
      );
      dispatch(
        setReceivedPaymentsByPaymentTypes({
          receivedPaymentsByPaymentTypes: response.data.data.service_orders,
        })
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const finalizeReceivedPaymentNFSe =
  ({
    link_nfse_2,
    link_nfse_xml,
    idordemdeservico,
  }: {
    link_nfse_2?: string;
    link_nfse_xml?: string;
    idordemdeservico: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingReport } = reportsReducer.actions;
    dispatch(setIsFetchingReport(true));

    try {
      await api.put(`/api/reports/receivedpayments/invoice/finalize`, {
        link_nfse_2,
        link_nfse_xml,
        idordemdeservico,
      });
      dispatch(fetchReceivedPayments({}));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const initializeReceivedPaymentNFSe =
  ({
    status,
    idordemdeservico,
    idusuario,
    emitidaos,
  }: {
    status: "encaminhado" | "pendente" | "completo";
    idordemdeservico: string;
    idusuario: string;
    emitidaos: boolean;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingReport } = reportsReducer.actions;
    dispatch(setIsFetchingReport(true));

    try {
      await api.put(`/api/reports/receivedpayments/invoice/initialize`, {
        idordemdeservico,
        status,
        idusuario,
        emitidaos,
      });

      dispatch(fetchReceivedPayments({}));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchPriceList =
  (allConsult: boolean = false): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { setIsFetchingReport, setPriceList } = reportsReducer.actions;
    const { priceListFilterArray } = state.reports;
    const { allHealthPlansCategory } = state.healthPlanCategory;
    dispatch(setIsFetchingReport(true));
    const queryParameters = queryStringFromFilterArray(priceListFilterArray);
    try {
      const response = await api.get(`api/reports/pricelist${queryParameters}`);
      if (allConsult) {
        let filtered = response.data.data
          ?.filter(
            (obj: any) =>
              !allHealthPlansCategory.some((n) => {
                if (obj?.health) return obj?.health.id !== n.id;
                return false;
              })
          )
          .map((element: any) => {
            let healthPlansIncludes = { ...element };
            let listIdconveniosFilter = priceListFilterArray.find(
              (e) => e.key === "idconvenio"
            );
            if (
              listIdconveniosFilter?.value &&
              listIdconveniosFilter?.value.length > 0
            ) {
              allHealthPlansCategory.forEach((convenio) => {
                if (
                  listIdconveniosFilter?.value.some(
                    (e: string) => e === convenio.id
                  )
                ) {
                  healthPlansIncludes[
                    convenio.nome.toLowerCase().trim()
                  ] = `N/A`;
                }
              });
            } else {
              allHealthPlansCategory.forEach((convenio) => {
                healthPlansIncludes[convenio.nome.toLowerCase().trim()] = `N/A`;
              });
            }
            return healthPlansIncludes;
          });
        response.data.data?.map((e: any) => {
          if (e.health?.idconvenio) {
            filtered.map((privado: any) => {
              if (privado.iditemservico === e.iditemservico) {
                privado[
                  (e?.health.nome).toLowerCase().trim()
                ] = `${formatCurrency(
                  Number(e.sale_price)
                )}(PC) ${formatCurrency(
                  Number(e.transfer_health)
                )}(RC) ${formatCurrency(
                  Number(e.transfer_cost)
                )}(RM) ${formatCurrency(Number(e.profit))}(M)`;
              }
            });
          }
        });
        dispatch(setPriceList({ priceList: filtered }));
      } else {
        dispatch(setPriceList({ priceList: response.data.data as any[] }));
      }
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingReport(false));
    }
  };

export const fetchServiceResults =
  ({ idprofissional }: { idprofissional?: string }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setServiceResults } = reportsReducer.actions;
    const state = getState();
    const { serviceResultsFilterArray } = state.reports;
    dispatch(setIsFetchingReport(true));

    const queryParameters = queryStringFromFilterArray(
      serviceResultsFilterArray
    );

    try {
      const response = await api.get(
        `api/reports/serviceresults${queryParameters}`
      );
      dispatch(setServiceResults({ serviceResults: response.data.data }));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchHealthPlansServiceResults = (): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setHealthPlansServiceResults } = reportsReducer.actions;
    
    const state = getState();
    const { healthPlansServiceResultsFilterArray } = state.reports;

    const queryParameters = queryStringFromFilterArray(
      healthPlansServiceResultsFilterArray
    );

    dispatch(setIsFetchingReport(true));

    try {
      const response = await api.get(
        `api/reports/healthplans-serviceresults${queryParameters}`
      );

      dispatch(setHealthPlansServiceResults({ healthPlansServiceResults: response.data.data }));
    } catch (error: AxiosError | any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingReport(false));
    }
  };

export const clearListPriceList = (): AppThunk => (dispatch) => {
  const { updateClearFilterPriceList, setPriceList } = reportsReducer.actions;
  dispatch(setPriceList({ priceList: null }));
  dispatch(
    updateClearFilterPriceList([
      { key: "idclinica", value: [] },
      { key: "idcategoriaservico", value: [] },
      { key: "idprofissional", value: [] },
      { key: "idconvenio", value: [] },
      { key: "tipoconsulta", value: null },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
    ])
  );
};
export const clearListServiceResults = (): AppThunk => (dispatch) => {
  const { updateClearFilterServiceResults, setServiceResults } =
    reportsReducer.actions;
  dispatch(setServiceResults({ serviceResults: null }));
  dispatch(
    updateClearFilterServiceResults([
      { key: "idclinica", value: null },
      { key: "idcategoriaservico", value: [] },
      { key: "idprofissional", value: null },
      { key: "idconvenio", value: [] },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
      { key: "tipoconsulta", value: null },
      { key: "metodopagamento", value: null },
    ])
  );
};
export const clearListHealthPlansServiceResults = (): AppThunk => (dispatch) => {
  const { updateClearFilterHealthPlansServiceResults, setHealthPlansServiceResults } =
    reportsReducer.actions;
  dispatch(setHealthPlansServiceResults({ healthPlansServiceResults: null }));
  dispatch(
    updateClearFilterHealthPlansServiceResults([
      { key: "idclinica", value: null },
      { key: "idcategoriaservico", value: [] },
      { key: "idprofissional", value: null },
      { key: "idconvenio", value: [] },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
    ])
  );
};
export const clearListRankingServices = (): AppThunk => (dispatch) => {
  const { updateClearFilterRankingServices, setRankServices } =
    reportsReducer.actions;
  dispatch(setRankServices({ rankServices: null }));
  dispatch(
    updateClearFilterRankingServices([
      { key: "idclinica", value: [] },
      { key: "idcategoriaservico", value: [] },
      { key: "iditemservico", value: [] },
      { key: "idprofissional", value: [] },
      { key: "idconvenio", value: [] },
      { key: "status", value: null },
      { key: "idcanalatendimento", value: [] },
      { key: "idusuario", value: [] },
      { key: "paciente", value: null },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
      { key: "status_invoice", value: null },
      { key: "doc_paciente", value: null },
      { key: "idpaciente", value: null },
      { key: "tipoconsulta", value: null },
    ])
  );
};
export const clearListCustomerQuotes = (): AppThunk => (dispatch) => {
  const { updateClearFilterCustomerQuotes, setCustomerQuotesReports } =
    reportsReducer.actions;
  dispatch(setCustomerQuotesReports(null));
  dispatch(
    updateClearFilterCustomerQuotes([
      { key: "idclinica", value: [] },
      { key: "cargos", value: [] },
      { key: "paciente", value: null },
      { key: "codigo", value: [] },
      { key: "status", value: [] },
      { key: "usuario", value: [] },
      { key: "idusuario", value: [] },
      { key: "modificado", value: null },
      { key: "quitado", value: [] },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
      { key: "changelogs", value: true },
      // { key: "dataquitado", value: null },
      { key: "tipoconsulta", value: null },
      { key: "solicitante", value: null },
      { key: "idvendedor", value: [] },
    ])
  );
};
export const clearListAppointmentByStatus = (): AppThunk => (dispatch) => {
  const { setIsFetchingReport, setAppointmentsByStatus, updateClearFilter } =
    reportsReducer.actions;
  dispatch(setAppointmentsByStatus({ appointmentByStatus: null }));
  dispatch(
    updateClearFilter([
      { key: "idclinica", value: [] },
      { key: "idcategoriaservico", value: [] },
      { key: "iditemservico", value: [] },
      { key: "idprofissional", value: [] },
      { key: "idconvenio", value: [] },
      { key: "status", value: null },
      { key: "idcanalatendimento", value: [] },
      { key: "idusuario", value: [] },
      { key: "idconfirmador", value: [] },
      { key: "paciente", value: null },
      { key: "start_date", value: null },
      { key: "end_date", value: null },
      { key: "status_invoice", value: null },
      { key: "doc_paciente", value: null },
      { key: "idpaciente", value: null },
      { key: "tipoconsulta", value: null },
    ])
  );
};

export const fetchAppointmentByStatus =
  ({ idprofissional }: { idprofissional?: string }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setAppointmentsByStatus } =
      reportsReducer.actions;
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    dispatch(setIsFetchingReport(true));

    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );

    try {
      const response = await api.get(
        `api/reports/appointmentbystatus${queryParameters}`
      );
      dispatch(
        setAppointmentsByStatus({ appointmentByStatus: response.data.data })
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchProfessionalPerformance =
  ({ idprofissional }: { idprofissional?: string }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setProfessionalPerformance } =
      reportsReducer.actions;
    const state = getState();
    const { receivedPaymentsFilterArray } = state.reports;
    dispatch(setIsFetchingReport(true));

    const queryParameters = queryStringFromFilterArray(
      receivedPaymentsFilterArray
    );

    try {
      const response = await api.get(
        `api/reports/professionalperformance${queryParameters}`
      );
      dispatch(
        setProfessionalPerformance({
          professionalPerformance: response.data.data,
        })
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
function getServiceItemsNames(customerQuoteItems: CustomerQuoteItems[]) {
  if (customerQuoteItems.length > 0) {
    const serviceItemsNames = customerQuoteItems.map(
      (item) => item.itemservico.nome
    );

    let text = "";

    serviceItemsNames.forEach((item) => {
      if (serviceItemsNames[serviceItemsNames.length - 1] === item) {
        text += `${item}.`;
      } else {
        text += `${item}, `;
      }
    });

    return text;
  }

  return "Nenhum item cadastrado";
}

export const fetchCustomerQuotesReportsCsv =
  ({ limit = 99 }: { limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReportCsv, setCustomerQuotesReportsCsv } =
      reportsReducer.actions;
    const state = getState();
    const { customerQuotesFilterArray } = state.reports;
    dispatch(setIsFetchingReportCsv(true));

    const queryParameters = queryStringFromFilterArray(
      customerQuotesFilterArray
    );

    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${1}&limit=${limit}`
        : `&page=${1}&limit=${limit}`;
    try {
      // const response = await api.get(
      //   `api/customerquotes${queryParameters}${pageAndLimit}`
      // );
      const response = await api.get(
        `api/reports/customerquotes${queryParameters}${pageAndLimit}`
      );
      const newQuotes: ICustomerQuoteReportData[] = [];

      if (response.data.success) {
        (
          response.data.data as ICustomerQuoteReportsResultsTable
        ).customer_quotes.map((el) => {
          // const quitado = el.historico.find((history) =>
          //   history.descricao.includes("quitado")
          // )?.usuario?.nome;
          // const modificado =
          //   el.historico[el.historico.length - 1]?.usuario?.nome;
          // el.orcamentoString = getServiceItemsNames(el.itensorcamento);
          // el.datacriacao = el.datacriacao
          //   ? dayjs(el.datacriacao).format("DD/MM/YYYY HH:mm")
          //   : "";
          // el.dataexecucao = el.dataexecucao
          //   ? dayjs(el.dataexecucao).format("DD/MM/YYYY HH:mm")
          //   : "NÃO TOTALMENTE EXECUTADA";

          el.date = dayjs(el.date).format("DD/MM/YYYY HH:mm");

          if (el.execution_date !== "Não executado") {
            el.execution_date = dayjs(el.execution_date).format(
              "DD/MM/YYYY HH:mm"
            );
          }

          el.code = `'${el.code}`;

          const roundedLiquidValue =
            Math.round(el.total_liquid_value * 100) / 100;

          el.total_liquid_value = Number(roundedLiquidValue.toFixed(2));

          newQuotes.push({
            ...el,
            // modificou: modificado ? modificado : "Não modificado",
            // quitador: quitado ? quitado : "Não quitado",
          });
        });
      }

      dispatch(setCustomerQuotesReportsCsv(newQuotes));
      dispatch(setIsFetchingReportCsv(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReportCsv(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchCustomerQuotesReports =
  ({ page = 1, limit = 20 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingReport, setCustomerQuotesReports } =
      reportsReducer.actions;
    const state = getState();
    const { customerQuotesFilterArray } = state.reports;

    dispatch(setIsFetchingReport(true));

    const queryParameters = queryStringFromFilterArray(
      customerQuotesFilterArray
    );

    const pageAndLimit =
      queryParameters.length === 0
        ? `?page=${page}&limit=${limit}`
        : `&page=${page}&limit=${limit}`;

    try {
      const response = await api.get(
        `api/reports/customerquotes${queryParameters}${pageAndLimit}`
      );
      // const newQuotes: ICustomerQuoteObject[] = [];
      // if (response.data.success) {
      //   response.data.data.customer_quotes.map((el: IOrcamento) => {
      //     const quitado = el.historico.find((history) =>
      //       history.descricao.includes("quitado")
      //     )?.usuario?.nome;
      //     const modificado =
      //       el.historico[el.historico.length - 1]?.usuario?.nome;
      //     newQuotes.push({
      //       ...el,
      //       modificou: modificado ? modificado : "Não modificado",
      //       quitador: quitado ? quitado : "Não quitado",
      //     });
      //   });
      // }

      // dispatch(
      //   setCustomerQuotesReports({
      //     ...response.data.data,
      //     customer_quotes: newQuotes,
      //   })
      // );
      dispatch(setCustomerQuotesReports(response.data.data));
      dispatch(setCustomerQuoteTotal(response.data.data.total));
      dispatch(setIsFetchingReport(false));
    } catch (error: AxiosError | any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const handleReceivedPaymentGenerateNF =
  ({
    data,
    clinicanfse,
    idusuario,
    cb,
  }: {
    data: IPaymentRows;
    idusuario: string;
    clinicanfse?: IClinicNfse;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingNF, setIsProcessingNfseDialog } =
      reportsReducer.actions;

    dispatch(setIsFetchingNF(true));
    if (!clinicanfse) {
      dispatch(setIsFetchingNF(true));
      return toast.info(
        "CONFIGURAÇÃO DE NOTA FISCAL NÃO REALIZADA PARA A CLÍNICA SELECIONADA"
      );
    }
    const cidade = data.paciente.nomecidade
      ? data.paciente.nomecidade
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : data.clinica?.cidade
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

    const codigoCidade = cityOptions.find(
      (city) => city.nome_da_cidade === cidade
    );

    if (!codigoCidade) {
      setIsProcessingNfseDialog(false);
      return toast.info("Endereço não encontrado. Verifique e tente novamente");
    }

    dispatch(setIsProcessingNfseDialog(true));
    dispatch(
      initializeReceivedPaymentNFSe({
        idordemdeservico: data.idordemdeservico,
        status: "encaminhado",
        idusuario,
        emitidaos: false,
      })
    );

    const desc_servicos =
      data.paciente &&
      !!data.paciente.nomeresponsavel &&
      data.paciente.nomeresponsavel !== data.paciente.nomepaciente &&
      data.paciente.cpfresponsavel
        ? `Paciente (${data.paciente.nomepaciente}) - Solidário financeiro (habilitado): ${data.paciente.nomeresponsavel}`
        : `Paciente (${data.paciente.nomepaciente})`;

    const endereco = data.paciente.logradouro
      ? `${data.paciente.logradouro}, ${data.paciente.complemento}`
      : `${data.clinica?.logradouro}, ${data.clinica?.complemento}`;

    const numero = data.paciente.numero
      ? data.paciente.numero
      : data.clinica?.numero;
    const bairro = data.paciente.bairro
      ? data.paciente.bairro
      : data.clinica?.bairro;
    const estado = data.paciente.siglaestado
      ? data.paciente.siglaestado
      : data.clinica?.estado;
    const cep = data.paciente.cep ? data.paciente.cep : data.clinica?.cep;

    const metodospagamentos = data.metodospagamentos.map((metodopagamento) => ({
      idmetodopagamento: metodopagamento.idmetodopagamento,
      idcontabancaria: metodopagamento.idcontabancaria,
      tipopagamento: metodopagamento.formapagamento,
      credito: metodopagamento.credito,
      contabancaria: {
        agencia: metodopagamento.contabancaria?.agencia,
        banco: metodopagamento.contabancaria?.banco,
        conta: metodopagamento.contabancaria?.conta,
        nome: metodopagamento.contabancaria?.nome,
        valorlimite: metodopagamento.contabancaria?.valorlimite,
      },
    }));

    try {
      const res = await apiNf.post("/api/nfse", {
        idclientesistema: data.paciente.idpaciente,
        nome: data.paciente.nomeresponsavel
          ? data.paciente.nomeresponsavel
          : data.nomepaciente,
        email: data.paciente.email,
        cpf:
          data.paciente.cpfresponsavel &&
          data.paciente.cpfresponsavel !== data.paciente.cpf
            ? data.paciente.cpfresponsavel
            : data.paciente.cpf,
        endereco: endereco,
        endereco_numero: numero,
        bairro: bairro,
        cod_cidade_ibge: codigoCidade.codigo_do_IGBE, // codigoCidade.codigo_do_IGBE,
        estado: estado,
        cep: cep,
        tipo_pagamento:
          data.valorpago.length > 0
            ? data.valorpago[0].nomemetodopagamento
            : "",
        desc_servicos,
        idordemservico: data.idordemdeservico,
        valor_servicos: data.valoratual.replaceAll(".", ","),
        idclinica_sistema: data.idclinica,
        ...clinicanfse,
        parcelas:
          data.valorpago.length > 0 && data.valorpago[0].numeroparcelas > 0
            ? data.valorpago[0].numeroparcelas
            : 1,
        metodospagamentos: metodospagamentos,
      });
      if (!res.data) {
        toast.error("Não foi possível gerar a Nota Fiscal. Tente novamente!");
      }

      if (res.data.data.status === "sucesso") {
        dispatch(
          finalizeReceivedPaymentNFSe({
            idordemdeservico: data.idordemdeservico,
            link_nfse_2: res.data.data.link_2_via,
            link_nfse_xml: res.data.data.link_xml,
          })
        );

        toast.success(
          res.data.data.message ||
            "A Nota Fiscal foi gerada com sucesso! Verifique seu e-mail."
        );
      } else {
        toast.error(res.data.data.message || "Erro ao gerar Nota Fiscal");
        dispatch(
          initializeReceivedPaymentNFSe({
            idordemdeservico: data.idordemdeservico,
            status: "pendente",
            idusuario,
            emitidaos: false,
          })
        );
      }
      dispatch(setIsProcessingNfseDialog(false));
      dispatch(setIsFetchingNF(false));
      if (cb) cb();
    } catch (error: AxiosError | any) {
      dispatch(
        initializeReceivedPaymentNFSe({
          idordemdeservico: data.idordemdeservico,
          status: "pendente",
          idusuario,
          emitidaos: false,
        })
      );
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      dispatch(setIsFetchingNF(false));
      dispatch(setIsProcessingNfseDialog(false));
    }
  };

export const handleReceivedPaymentGenerateNFInLote =
  ({
    data,
    idusuario,
  }: {
    data: IPaymentRows[];
    idusuario: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingNF } = reportsReducer.actions;
    const id = toast.loading(
      "As notas fiscais estão sendo geradas... Não saia desta tela!"
    );
    let isError = false;

    dispatch(setIsProcessingNfseDialog(true));
    dispatch(setShowNfseDialog(false));
    dispatch(setIsFetchingNF(true));

    for (const paymentRow of data) {
      const cidade = paymentRow.paciente.nomecidade
        ? paymentRow.paciente.nomecidade
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : paymentRow.clinica?.cidade
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

      const codigoCidade = cityOptions.find(
        (city) => city.nome_da_cidade === cidade
      );

      if (!codigoCidade) {
        setIsProcessingNfseDialog(false);
        dispatch(setIsFetchingNF(false));
        return toast.info(
          "Endereço não encontrado. Verifique e tente novamente"
        );
      }

      const endereco = paymentRow.paciente.logradouro
        ? `${paymentRow.paciente.logradouro}, ${paymentRow.paciente.complemento}`
        : `${paymentRow.clinica?.logradouro}, ${paymentRow.clinica?.numero}/${paymentRow.clinica?.complemento}`;
        const numero = paymentRow.clinica?.numero;
        const bairro = paymentRow.clinica?.bairro;
        const estado = paymentRow.clinica?.estado;
        const cep = paymentRow.clinica?.cep;

      const desc_servicos =
        paymentRow.paciente &&
        !!paymentRow.paciente.nomeresponsavel &&
        paymentRow.paciente.nomeresponsavel !== paymentRow.paciente.nomepaciente
          ? `Paciente (${paymentRow.paciente.nomepaciente}) - Solidário financeiro (habilitado): ${paymentRow.paciente.nomeresponsavel}`
          : `Paciente (${paymentRow.paciente.nomepaciente})`;

      const metodospagamentos = paymentRow.metodospagamentos.map(
        (metodopagamento) => ({
          idmetodopagamento: metodopagamento.idmetodopagamento,
          idcontabancaria: metodopagamento.idcontabancaria,
          tipopagamento: metodopagamento.formapagamento,
          credito: metodopagamento.credito,
          contabancaria: {
            agencia: metodopagamento.contabancaria?.agencia,
            banco: metodopagamento.contabancaria?.banco,
            conta: metodopagamento.contabancaria?.conta,
            nome: metodopagamento.contabancaria?.nome,
            valorlimite: metodopagamento.contabancaria?.valorlimite,
          },
        })
      );

      dispatch(
        initializeReceivedPaymentNFSe({
          idordemdeservico: paymentRow.idordemdeservico,
          status: "encaminhado",
          idusuario,
          emitidaos: false,
        })
      );

      try {
        const res = await apiNf.post("/api/nfse", {
          idclientesistema: paymentRow.paciente.idpaciente,
          nome: paymentRow.paciente.nomeresponsavel
            ? paymentRow.paciente.nomeresponsavel
            : paymentRow.nomepaciente,
          email: paymentRow.paciente.email,
          cpf:
            paymentRow.paciente.cpfresponsavel &&
            paymentRow.paciente.cpfresponsavel !== paymentRow.paciente.cpf
              ? paymentRow.paciente.cpfresponsavel
              : paymentRow.paciente.cpf,
          endereco,
          endereco_numero: numero,
          bairro,
          cod_cidade_ibge: codigoCidade.codigo_do_IGBE,
          estado,
          cep,
          tipo_pagamento:
            paymentRow.valorpago.length > 0
              ? paymentRow.valorpago[0].nomemetodopagamento
              : "",
          desc_servicos,
          idordemservico: paymentRow.idordemdeservico,
          valor_servicos: paymentRow.valoratual.replaceAll(".", ","),
          idclinica_sistema: paymentRow.idclinica,
          ...paymentRow.clinica?.clinicanfse,
          parcelas:
            paymentRow.valorpago.length > 0
              ? paymentRow.valorpago[0].numeroparcelas
              : 1,
          metodospagamentos: metodospagamentos,
        });

        if (!res.data) {
          toast.error("Não foi possível gerar a Nota Fiscal. Tente novamente!");
          isError = true;
        }

        if (res.data.data.status === "sucesso") {
          dispatch(
            finalizeReceivedPaymentNFSe({
              idordemdeservico: paymentRow.idordemdeservico,
              link_nfse_2: res.data.data.link_2_via,
              link_nfse_xml: res.data.data.link_xml,
            })
          );
          isError = false;
        } else {
          dispatch(
            initializeReceivedPaymentNFSe({
              idordemdeservico: paymentRow.idordemdeservico,
              status: "pendente",
              idusuario,
              emitidaos: false,
            })
          );
          isError = true;
          toast.error(
            res.data.data.cod_verificacao || "Erro ao gerar Nota Fiscal"
          );
        }
        dispatch(setIsFetchingNF(false));
      } catch (error: AxiosError | any) {
        dispatch(
          initializeReceivedPaymentNFSe({
            idordemdeservico: paymentRow.idordemdeservico,
            status: "pendente",
            idusuario,
            emitidaos: false,
          })
        );
        dispatch(setIsFetchingNF(false));
        isError = true;
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    }
    dispatch(setSelectedPaymentsRowsToNfse({ selectedPaymentsRowsToNfse: [] }));
    dispatch(setIsProcessingNfseDialog(false));

    if (isError) {
      toast.update(id, {
        render: "Algo ocorreu na emissão das NFS-e. Tente novamente!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } else {
      toast.update(id, {
        render: "NFS-e emitidas com sucesso",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  export const handleReceivedPaymentGenerateNFInLoteUpdate =
  ({
    data,
    idusuario,
  }: {
    data: IPaymentRows[];
    idusuario: string;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingNF } = reportsReducer.actions;
    const id = toast.loading(
      "As notas fiscais estão sendo geradas... Não saia desta tela!"
    );
    let isError = false;

    dispatch(setIsProcessingNfseDialog(true));
    dispatch(setShowNfseDialog(false));
    dispatch(setIsFetchingNF(true));

    for (const paymentRow of data) {
      dispatch(
        initializeReceivedPaymentNFSe({
          idordemdeservico: paymentRow.idordemdeservico,
          status: "completo",
          idusuario,
          emitidaos: true,
        })
      );
    }
    dispatch(setSelectedPaymentsRowsToNfse({ selectedPaymentsRowsToNfse: [] }));
    dispatch(setIsProcessingNfseDialog(false));

    if (isError) {
      toast.update(id, {
        render: "Algo ocorreu na emissão das NFS-e. Tente novamente!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } else {
      toast.update(id, {
        render: "NFS-e atualizadas manualmente",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

export const {
  setIsFetchingReport,
  setReceivedPayments,
  setRankServices,
  setShowNfseDialog,
  updateReceivedPaymentsFilterArray,
  updateClearReceivedPaymentsFilterArray,
  setPriceList,
  setServiceResults,
  setCustomerQuoteLimit,
  setCustomerQuotePage,
  setCustomerQuoteTotal,
  updateCustomerQuotesFilterArray,
  updateServiceResultsFilterArray,
  updateHealthPlansServiceResultsFilterArray,
  updateClearFilterHealthPlansServiceResults,
  updatePriceListFilterArray,
  setSelectedPaymentsRowsToNfse,
  setIsProcessingNfseDialog,
  setCurrentPaymentRow,
  setShowNfseByPaymentTypesDialog,
  setReceivedPaymentsByPaymentTypes,
  setIsFetchingReportCsv,
} = reportsReducer.actions;

export default reportsReducer.reducer;
