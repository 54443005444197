import { IHealthplanStandardtable } from "models/Covenant";
import { IServiceItem } from "store/reducers/HealthPlanCategoryReducer/HealthPlanCategoryReducer.types";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  valor: Yup.number().required("Campo obrigatório"),
  repasseconvenio: Yup.number().required("Campo obrigatório"),
});

export const healthPlanCategoryValidationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório"),
});

export interface HealthPlanCategoryFormTypes {
  nome: string;
  idconvenio: string;
  ativo: boolean;
  calculoreais: boolean;
  calculouco: boolean;
  calculoporte: boolean;
  calculofilme: boolean;
  calculoch: boolean;
  valorchsadt?: number;
  valorchhm?: number;
  valorfilme?: number;
  valoruco?: number;
  segundoprocedimento?: number;
  terceiroprocedimento?: number;
  quartoprocedimento?: number;
}

export interface FormTypes {
  servicoprofissionais: string;
  iditemservico?: string;
  idconveniocategoria?: string;
  itemservico: any;
  valor: number;
  repasseconvenio: number;
  tipo_repasse: string;
  codigo: string;
  ativo: boolean;
}

export interface IAssociatedItem {
  codigoConvenio: string | undefined;
  id?: string;
  iditemservico: string;
  idconveniocategoria: string;
  codigo: string;
  valor: number;
  ativo: boolean;
  repasseconvenio: {
    valor: number;
    tipo: "fixo" | "porcentagem";
  };
  itemservico?: IServiceItem;
  servicoconvenioprofissionais: {
    id: string;
    idservicoconveniocategoria: string;
    idprofissional: string;
  }[];
  conveniocategoria: {
    id: string;
    idconvenio: string;
    nome: string;
    ativo: boolean;
    convenio: {
      idconvenio: string;
      nome: string;
      ativo: boolean;
      descontos: boolean;
    };
  };
  servicotabelapadrao?: IHealthplanStandardtable;
}

export interface ISelectOption {
  label: string;
  value: string;
  currency?: number;
}

export const initialOptions = {
  label: "",
  value: "",
  currency: 0,
  ativo: true,
};

export const healthPlanCategoryInitialState: HealthPlanCategoryFormTypes = {
  nome: "",
  idconvenio: "",
  ativo: true,
  calculoreais: false,
  calculouco: false,
  calculoporte: false,
  calculofilme: false,
  calculoch: false,
};

export const serviceItemsInitialState: FormTypes = {
  servicoprofissionais: "",
  itemservico: "",
  valor: 0,
  repasseconvenio: 0,
  tipo_repasse: "fixo",
  codigo: "",
  ativo: true,
};
