import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import { history } from "utils/history";
import {
	HealthPlanCategoryReducerState,
	ICreateHealthPlanCategory,
	HealthPlanCategoryFetchResponse,
	IServicosConvenioCategoria,
	ServiceHealthPlanCategoryFetchResponse,
	HealthPlanTableVersionFetchResponse,
	AddExceptionsFromStandardTableServicesFetchResponse,
	ExceptionsFromStandardTableServicesFetchResponse,
	ICreateHealthPlanCategoryServiceException,
} from "./HealthPlanCategoryReducer.types";

const initialState: HealthPlanCategoryReducerState = {
	healthPlansCategory: [],
	allHealthPlansCategory: [],
	healthPlanCategory: {
		ativo: true,
		idconvenio: "",
		nome: "",
		servicosconveniocategoria: [],
		calculoch: false,
		calculofilme: false,
		calculoporte: false,
		calculoreais: false,
		calculouco: false
	},
	healthPlanServiceItems: [],
	healthPlanTableVersions: [],
	healthPlanTableVersionsByItem: [],
	isFetchingHealthPlanTableVersions: false,
	isFetchingHealthPlansCategory: false,
	isFetchingHealthPlanCategory: false,
	isCreatingHealthPlanCategory: false,
	listPage: 0,
	listLimit: 6,
	listTotalSize: 0,
	associatedItems: [],
	idconveniocategoria: "",
	healthPlanFilterId: "",
	healthPlanCategoryFilterId: "",
	healthPlanServiceCategoryItems: [],
	professionals: null,
	isFetchingExceptionsFromStandardTableServices: false,
	isCreatingOrUpdatingExceptionFromStandardTableService: false,
	addStandardTableServices: [],
	standardTableServiceExceptions: [],
};

const healthPlanCategorySlice = createSlice({
	name: "healthPlanCategory",
	initialState,
	reducers: {
		setHealthPlansCategory: (state, { payload }) => {
			state.healthPlansCategory = payload;
		},
		setAllHealthPlansCategory: (state, { payload }) => {
			state.allHealthPlansCategory = payload;
		},
		setHealthPlanCategory: (state, { payload }) => {
			state.healthPlanCategory = payload;
		},
		setIsFetchingHealthPlansCategory: (state, { payload }) => {
			state.isFetchingHealthPlansCategory = payload;
		},
		setIsFetchingHealthPlanTableVersions: (state, { payload }) => {
			state.isFetchingHealthPlanTableVersions = payload;
		},
		setIsFetchingHealthPlanCategory: (state, { payload }) => {
			state.isFetchingHealthPlanCategory = payload;
		},
		setListPage: (state, { payload }) => {
			state.listPage = payload;
		},
		setListLimit: (state, { payload }) => {
			state.listLimit = payload;
		},
		setListTotalSize: (state, { payload }) => {
			state.listTotalSize = payload;
		},
		setIsCreatingHealthPlanCategory: (state, { payload }) => {
			state.isCreatingHealthPlanCategory = payload;
		},
		setAssociatedItems: (state, { payload }) => {
			state.associatedItems = payload;
		},
		setIdConvenioCategoria: (state, { payload }) => {
			state.idconveniocategoria = payload;
		},
		setHealthPlanServiceItems: (state, { payload }) => {
			state.healthPlanServiceItems = payload;
		},
		setHealthPlanFilterId: (state, { payload }) => {
			state.healthPlanFilterId = payload;
		},
		setHealthPlanCategoryFilterId: (state, { payload }) => {
			state.healthPlanCategoryFilterId = payload;
		},
		setHealthPlanServiceCategoryItems: (state, { payload }) => {
			state.healthPlanServiceCategoryItems = payload;
		},
		setProfessionals: (state, { payload }) => {
			state.professionals = payload;
		},
		setHealthPlanTableVersions: (state, { payload }) => {
			state.healthPlanTableVersions = payload;
		},
		setHealthPlanTableVersionsByItem: (state, { payload }) => {
			state.healthPlanTableVersionsByItem = payload;
		},
		setIsFetchingExceptionsFromStandardTableServices: (state, { payload }) => {
			state.isFetchingExceptionsFromStandardTableServices = payload;
		},
		setAddStandardTableServices: (state, { payload }) => {
			state.addStandardTableServices = payload;
		},
		setIsCreatingOrUpdatingExceptionFromStandardTableService: (state, { payload }) => {
			state.isCreatingOrUpdatingExceptionFromStandardTableService = payload;
		},
		setStandardTableServiceExceptions: (state, { payload }) => {
			state.standardTableServiceExceptions = payload;
		},
	},
});

export const fetchHealthPlansCategory =
	({
		page = 1,
		limit = 6,
		idconvenio,
	}: {
		page: number;
		limit: number;
		idconvenio?: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlansCategory,
			setListPage,
			setListTotalSize,
			setIsFetchingHealthPlansCategory,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlansCategory(true));

		try {
			if (!idconvenio) {
				const { data }: HealthPlanCategoryFetchResponse = await api.get(
					`/api/healthplancategories?page=${page}&limit=${limit}`
				);
				dispatch(setHealthPlansCategory(data.data.health_plan_categories));
				dispatch(setListPage(data.data.page));
				dispatch(setListTotalSize(data.data.total));
			} else {
				const { data }: HealthPlanCategoryFetchResponse = await api.get(
					`/api/healthplancategories?page=${page}&limit=${limit}&id=${idconvenio}`
				);
				dispatch(setHealthPlansCategory(data.data.health_plan_categories));
				dispatch(setListPage(data.data.page));
				dispatch(setListTotalSize(data.data.total));
			}
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlansCategory(false));
		}
	};

export const fetchHealthPlanCategoryById =
	(healthPlanCategoryId: string): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanCategory,
			setIsFetchingHealthPlanCategory,
			setIdConvenioCategoria,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: HealthPlanCategoryFetchResponse = await api.get(
				`/api/healthplancategories/${healthPlanCategoryId}`
			);
			dispatch(setHealthPlanCategory(data.data));
			dispatch(setIdConvenioCategoria(healthPlanCategoryId));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchServiceHealthPlanCategoryById =
	(healthPlanCategoryId: string): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanServiceItems,
			setIsFetchingHealthPlanCategory,
			setAssociatedItems,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: ServiceHealthPlanCategoryFetchResponse = await api.get(
				`/api/servicehealthplancategories?health_plan_category_id=${healthPlanCategoryId}&page=1&limit=9999`
			);
			dispatch(setHealthPlanServiceItems(data.data));
			dispatch(setAssociatedItems(data.data.service_health_plan_categories));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchServiceHealthPlanCategoryByServiceItemId =
	(iditemservico: string): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanServiceItems,
			setIsFetchingHealthPlanCategory,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: ServiceHealthPlanCategoryFetchResponse = await api.get(
				`/api/servicehealthplancategories/health-plans?iditemservico=${iditemservico}`
			);
			dispatch(setHealthPlanServiceItems(data.data));
			// dispatch(setAssociatedItems(data.data.service_health_plan_categories));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchServiceHealthPlanCategoryByIdProfessional =
	({
		id_health_plan_category,
		idprofissional,
	}: {
		id_health_plan_category: string;
		idprofissional: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanServiceCategoryItems,
			setIsFetchingHealthPlanCategory,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: ServiceHealthPlanCategoryFetchResponse = await api.get(
				`/api/healthplancategories/categories?id_health_plan_category=${id_health_plan_category}&idprofissional=${idprofissional}`
			);
			dispatch(setHealthPlanServiceCategoryItems(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchCategoriesByHealthPlanCategory =
	({
		id_health_plan_category,
	}: {
		id_health_plan_category: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanServiceCategoryItems,
			setIsFetchingHealthPlanCategory,
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: ServiceHealthPlanCategoryFetchResponse = await api.get(
				`/api/healthplancategories/categories?id_health_plan_category=${id_health_plan_category}`
			);
			dispatch(setHealthPlanServiceCategoryItems(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchFilteredHealthPlanCategory =
	({
		idconvenio,
		iditemservico,
	}: {
		idconvenio: string;
		iditemservico: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlanCategory } = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const { data }: HealthPlanCategoryFetchResponse = await api.get(
				`/api/healthplancategories/filtered?idconvenio=${idconvenio}&iditemservico=${iditemservico}`
			);
			dispatch(setHealthPlansCategory(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchProfessionalsByHealthPlanCategoryAndServiceItems =
	({
		service_item_ids,
		health_plan_category_id,
	}: {
		service_item_ids: string[];
		health_plan_category_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlanCategory, setProfessionals } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			const serviceItemsIds = service_item_ids.map((id) => {
				return `&service_item_ids=${id}`;
			});

			const queryServiceItems = serviceItemsIds.join("");

			const { data }: HealthPlanCategoryFetchResponse = await api.get(
				`api/healthplancategories/serviceitems/professionals?health_plan_category_id=${health_plan_category_id}${queryServiceItems}`
			);
			dispatch(setProfessionals(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const fetchAllHealthPlansCategories =
	(): AppThunk => async (dispatch, getState) => {
		const { setAllHealthPlansCategory, setIsFetchingHealthPlansCategory } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlansCategory(true));

		try {
			const { data }: HealthPlanCategoryFetchResponse = await api.get(
				`/api/healthplancategories?page=1&limit=9999`
			);
			dispatch(setAllHealthPlansCategory(data.data.health_plan_categories));
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlansCategory(false));
		}
	};

export const createHealthPlanCategory =
	(healthPlanCategory: ICreateHealthPlanCategory): AppThunk =>
	async (dispatch, getState) => {
		const { setIsCreatingHealthPlanCategory, setIdConvenioCategoria } =
			healthPlanCategorySlice.actions;
		dispatch(setIsCreatingHealthPlanCategory(true));
		try {
			const { data } = await api.post(
				"/api/healthplancategories",
				healthPlanCategory
			);
			const healthPlanCategoryId = await data.data.id;
			toast.success("Plano criado com sucesso");
			dispatch(setIdConvenioCategoria(healthPlanCategoryId));
			history.push(`/planos-convenio/form/${healthPlanCategoryId}`)
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			setIsCreatingHealthPlanCategory(false);
		}
	};

export const createHealthPlanCategoryService =
	(healthPlanCategoryServices: IServicosConvenioCategoria[]): AppThunk =>
	async (dispatch, getState) => {
		const { idconveniocategoria } = getState().healthPlanCategory;
		const { setIsCreatingHealthPlanCategory } = healthPlanCategorySlice.actions;
		dispatch(setIsCreatingHealthPlanCategory(true));
		try {
			await api.post(
				"/api/servicehealthplancategories", {
					services_health_plan_category: healthPlanCategoryServices
			});
			toast.success("Serviços vinculados ao plano com sucesso", {
				toastId: "createHealthPlanCategoryService",
			});
			dispatch(fetchServiceHealthPlanCategoryById(idconveniocategoria));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingHealthPlanCategory(false));
		}
	};

export const editHealthPlanCategory =
	({
		health_plan_category_id,
		body,
	}: {
		health_plan_category_id: string;
		body: ICreateHealthPlanCategory;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlanCategory } = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			await api.put(
				`/api/healthplancategories/${health_plan_category_id}`,
				body
			);
			toast.success("Plano editado com sucesso", {
				toastId: "editHealthPlanCategory",
			});
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const editHealthPlanCategoryService =
	({
		service_health_plan_category_id,
		body,
	}: {
		service_health_plan_category_id: string;
		body: IServicosConvenioCategoria;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlanCategory } = healthPlanCategorySlice.actions;
		const { idconveniocategoria } = getState().healthPlanCategory;
		dispatch(setIsFetchingHealthPlanCategory(true));
		try {
			await api.put(
				`/api/servicehealthplancategories/${service_health_plan_category_id}`,
				body
			);
			toast.success("Itens de serviços editados com sucesso", {
				toastId: "editHealthPlanCategoryService",
			});
			dispatch(fetchServiceHealthPlanCategoryById(idconveniocategoria));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanCategory(false));
		}
	};

export const deleteHealthPlanCategory =
	(health_plan_category_id: string, active: boolean): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlansCategory } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlansCategory(true));
		try {
			await api.delete(`/api/healthplancategories/${health_plan_category_id}`);
			dispatch(fetchAllHealthPlansCategories());
			toast.success(`Plano de convênio ${!active ? "ativado" : "desativado"} com sucesso`);
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlansCategory(false));
		}
	};

export const deleteHealthPlanCategoryService =
	(service_health_plan_category_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { idconveniocategoria } = getState().healthPlanCategory;
		const { setIsFetchingHealthPlansCategory } =
			healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlansCategory(true));
		try {
			await api.delete(
				`/api/servicehealthplancategories/${service_health_plan_category_id}`
			);
			dispatch(fetchAllHealthPlansCategories());
			toast.success("Serviço desvinculado com sucesso", {
				toastId: "deleteHealthPlanCategoryService",
			});
			dispatch(fetchServiceHealthPlanCategoryById(idconveniocategoria));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlansCategory(false));
		}
	};

export const fetchHealthPlanTableVersions =
	(): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanTableVersions
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlansCategory(true));

		try {
			const { data }: HealthPlanTableVersionFetchResponse = await api.get(
				`/api/healthplan/tableversions`
			);
			dispatch(setHealthPlanTableVersions(data.data.health_plan_table_versions));

		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlansCategory(false));
		}
	};

export const fetchHealthPlanTableVersionsByItem =
	(tuss_code_id: string): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlanTableVersionsByItem,
			setIsFetchingHealthPlanTableVersions
		} = healthPlanCategorySlice.actions;
		dispatch(setIsFetchingHealthPlanTableVersions(true));

		try {
			const { data }: HealthPlanTableVersionFetchResponse = await api.get(
				`/api/healthplan/tableversions/items?tuss_code_id${tuss_code_id}`
			);
		
			dispatch(setHealthPlanTableVersionsByItem(data.data.health_plan_table_versions));

		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlanTableVersions(false));
		}
	};

export const fetchAddExceptionsFromStandardTableServices =
	({ health_plan_category_id }: { health_plan_category_id: string }): AppThunk =>
	async (dispatch) => {
		const {
			setAddStandardTableServices
		} = healthPlanCategorySlice.actions;

		dispatch(setIsFetchingExceptionsFromStandardTableServices(true));

		try {
			const queryParams = `?health_plan_category_id=${health_plan_category_id}`;

			const { data }: AddExceptionsFromStandardTableServicesFetchResponse = await api.get(
				`/api/healthplancategories/standardtable/add-service-exceptions${queryParams}`
			);

			dispatch(setAddStandardTableServices(data.data.standard_table_services));

		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExceptionsFromStandardTableServices(false));
		}
	};

export const fetchExceptionsFromStandardTableServices =
	(): AppThunk =>
	async (dispatch) => {
		const {
			setStandardTableServiceExceptions
		} = healthPlanCategorySlice.actions;

		dispatch(setIsFetchingExceptionsFromStandardTableServices(true));

		try {
			// const queryParams = `?health_plan_category_id=${health_plan_category_id}`;

			const { data }: ExceptionsFromStandardTableServicesFetchResponse = await api.get(
				`/api/healthplancategory/service-exceptions`
			);

			dispatch(setStandardTableServiceExceptions(data.data.service_health_plan_category_exceptions));

		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingExceptionsFromStandardTableServices(false));
		}
	};

export const createExceptionFromStandardTableServices =
	({ data }: { data: ICreateHealthPlanCategoryServiceException }): AppThunk =>
	async (dispatch) => {
		dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(true));

		try {
			const response = await api.post(`/api/healthplancategory/service-exceptions`, data);

			if (response.data.success) {
				dispatch(fetchExceptionsFromStandardTableServices())
				dispatch(fetchAddExceptionsFromStandardTableServices({ 
					health_plan_category_id: data.idconveniocategoria 
				}))
			}
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(false));
		}
	};

export const deleteExceptionFromStandardTableServices =
	({ 
		idexcecao, 
		idconveniocategoria 
	}: { 
		idexcecao: string,
		idconveniocategoria: string 
	}): AppThunk =>
	async (dispatch) => {
		dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(true));

		try {
			await api.delete(`/api/healthplancategory/service-exceptions/${idexcecao}`);

			dispatch(fetchExceptionsFromStandardTableServices())
			dispatch(fetchAddExceptionsFromStandardTableServices({ 
				health_plan_category_id: idconveniocategoria 
			}))
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(false));
		}
	};

export const editExceptionFromStandardTableServices =
	({ 
		data, 
		idconveniocategoria, 
		idexcecao 
	}: { 
		data: {
			repasseconvenio: {
				valor: number,
				tipo: "fixo" | "porcentagem",
			}
		},
		idconveniocategoria: string,
		idexcecao: string
	}): AppThunk =>
	async (dispatch) => {
		dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(true));

		try {
			const response = await api.put(`/api/healthplancategory/service-exceptions/${idexcecao}`, data);

			if (response.data.success) {
				dispatch(fetchExceptionsFromStandardTableServices())
				dispatch(fetchAddExceptionsFromStandardTableServices({ 
					health_plan_category_id: idconveniocategoria
				}))
			}
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsCreatingOrUpdatingExceptionFromStandardTableService(false));
		}
	};

export const {
	setHealthPlansCategory,
	setHealthPlanTableVersionsByItem,
	setIsFetchingHealthPlansCategory,
	setListPage,
	setListTotalSize,
	setListLimit,
	setAssociatedItems,
	setIdConvenioCategoria,
	setHealthPlanFilterId,
	setHealthPlanCategoryFilterId,
	setHealthPlanServiceItems,
	setHealthPlanServiceCategoryItems,
	setProfessionals,
	setIsFetchingExceptionsFromStandardTableServices,
	setIsCreatingOrUpdatingExceptionFromStandardTableService
} = healthPlanCategorySlice.actions;

export default healthPlanCategorySlice.reducer;
