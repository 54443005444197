import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootState } from "store";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateRangeDialog from "components/DateRangeDialog";
import TitleBar from "components/TitleBar";
import { FullWidthContainer } from "components/layout/base";
import { autocompleteDefaultProps } from "models/Autocomplete";
import SkeletonTable from "pages/CustomersQuote/Form/components/ItemsTable/components/SkeletonTable";
import {
  clearUpdateReleaseMedicalRecordsFecthInterval,
  fetchMedicalRecordsPreApps,
  setLimit,
  setPage,
  startUpdateReleaseMedicalRecordsFecthInterval,
  updateFilter,
} from "store/reducers/ReleaseMedicalRecordsReducer";
import ReleaseMedicalRecordsTable from "./components/ReleaseMedicalRecordsTable";

import { Loader } from "components/Loader";
import { fetchProfessionals } from "store/reducers/ProfessionalsReducer";

type ISelectOption = {
  label: string;
  value: string;
};

export default function PreAppointmentReleaseMedicalRecords() {
  const dispatch = useDispatch();
  const {
    users: { signedInUser },
    professionals: { isFetchingProfessionals, professionals },
    releaseMedicalRecords: {
      limit,
      isFetchingReleaseMedicalRecords,
      page,
      filterArray,
      releaseMedicalRecords,
    },
  } = useSelector((state: rootState) => state);

  const [clinicFilterValue, setClinicFilterValue] =
    useState<ISelectOption | null>(null);
  const [filterDate, setFilterDate] = useState([
    dayjs(new Date()).format("YYYY-MM-DD").toString(),
    dayjs(new Date()).format("YYYY-MM-DD").toString(),
  ]);
  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] =
    useState<boolean>(false);
  useState<boolean>(false);
  const [currentProfessionalFilter, setCurrentProfessionalFilter] =
    useState<ISelectOption>({ label: "", value: "" });

  const [status, setStatus] = useState<ISelectOption>({
    label: "TODOS",
    value: "",
  });
  const [order, setOrder] = useState<ISelectOption>({
    label: "Ordem de chegada",
    value: "check-in",
  });

  const fetchPatients = useCallback(() => {
    dispatch(fetchMedicalRecordsPreApps({ page, limit }));
  }, [dispatch, page, limit]);

  const professionalsOptions = useMemo(() => {
    if (professionals && professionals?.length > 0) {
      const professionalsArray = professionals.map((professional) => {
        if (
          professional.ativo &&
          professional.cargo.nome.toUpperCase() !== "LABORATORIO" &&
          professional.cargo.nome.toUpperCase() !== "DIAGNOSTICO"
        ) {
          return {
            label: professional.nome.toUpperCase(),
            value: professional.idusuario,
          };
        }
      });

      return professionalsArray.filter((professional) => !!professional);
    }

    return [];
  }, [professionals]);

  const clinicsOptions = useMemo(() => {
    if (
      signedInUser &&
      signedInUser.clinicas &&
      signedInUser.clinicas?.length > 0
    ) {
      return signedInUser.clinicas.map((clinic) => {
        return {
          label: clinic.nome.toUpperCase(),
          value: clinic.idclinica,
        };
      });
    }

    return [];
  }, [signedInUser]);

  function onChangeDateRange(dateRange: any) {
    setFilterDate([dateRange[0].startDate, dateRange[0].endDate]);
    dispatch(
      updateFilter({
        key: "start_date",
        value: dayjs(dateRange[0].startDate).format("YYYY-MM-DD").toString(),
      })
    );
    dispatch(
      updateFilter({
        key: "end_date",
        value: dayjs(dateRange[0].endDate).format("YYYY-MM-DD").toString(),
      })
    );
  }

  function handleSearch() {
    dispatch(setPage(1));
    dispatch(setLimit(6));
    dispatch(fetchMedicalRecordsPreApps({ page: 1, limit: 6 }));
  }

  function resetAllFilters() {
    dispatch(
      updateFilter({
        key: "nomepaciente",
        value: null,
      })
    );
    dispatch(
      updateFilter({
        key: "start_date",
        value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
      })
    );
    dispatch(
      updateFilter({
        key: "end_date",
        value: dayjs(new Date()).format("YYYY-MM-DD").toString(),
      })
    );
    dispatch(
      updateFilter({
        key: "idprofissional",
        value: null,
      })
    );
    dispatch(
      updateFilter({
        key: "idclinica",
        value: null,
      })
    );
    dispatch(
      updateFilter({
        key: "preconsulta",
        value: null,
      })
    );
    dispatch(
      updateFilter({
        key: "order",
        value: null,
      })
    );
  }

  useEffect(() => {
    if (clinicsOptions && clinicsOptions.length === 1) {
      setClinicFilterValue(clinicsOptions[0]);
      dispatch(
        updateFilter({
          key: "idclinica",
          value: clinicsOptions[0].value,
        })
      );
    }
  }, [clinicsOptions]);

  useEffect(() => {
    dispatch(fetchProfessionals({ limit: 999 }));
    resetAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  useEffect(() => {
    if (!currentProfessionalFilter.value) {
      dispatch(clearUpdateReleaseMedicalRecordsFecthInterval());
    } else {
      dispatch(startUpdateReleaseMedicalRecordsFecthInterval({ page, limit }));
    }

    return () => {
      dispatch(clearUpdateReleaseMedicalRecordsFecthInterval());
    };
  }, [dispatch, currentProfessionalFilter.value, page, limit]);

  return signedInUser ? (
    <Grid container direction="column">
      <Grid item xs={12}>
        <TitleBar title="Pré-consultas" wrap>
          <Grid
            container
            spacing={3}
            style={{ padding: "1.5rem" }}
            alignItems="center"
          >
            <Grid item>
              <TextField
                style={{ fontSize: 14 }}
                variant="outlined"
                label="Nome do paciente"
                value={filterArray[0].value || ""}
                onChange={(e: any) => {
                  dispatch(
                    updateFilter({
                      key: "nomepaciente",
                      value: e.target.value,
                    })
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                {...autocompleteDefaultProps}
                options={[
                  { label: "TODOS", value: "" },
                  { label: "PENDENTE", value: "pendente" },
                  { label: "REALIZADO", value: "realizado" },
                ]}
                value={status}
                getOptionSelected={(
                  option: ISelectOption,
                  value: ISelectOption
                ) => {
                  return value.value === option.value;
                }}
                onChange={(_: any, newValue: ISelectOption | null) => {
                  if (!newValue?.value) {
                    setStatus({
                      label: "TODOS",
                      value: "",
                    });
                    dispatch(
                      updateFilter({
                        key: "preconsulta",
                        value: "",
                      })
                    );
                  } else {
                    setStatus({
                      label: newValue.label,
                      value: newValue.value,
                    });
                    dispatch(
                      updateFilter({
                        key: "preconsulta",
                        value: newValue.value,
                      })
                    );
                  }
                }}
                getOptionLabel={(option: { value: string; label: string }) =>
                  option.label
                }
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    style={{ minWidth: 200 }}
                    variant="outlined"
                    {...params}
                    label="Status"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                {...autocompleteDefaultProps}
                options={professionalsOptions as ISelectOption[]}
                value={currentProfessionalFilter}
                getOptionSelected={(
                  option: ISelectOption,
                  value: ISelectOption
                ) => {
                  return value.value === option.value;
                }}
                // @ts-ignore
                onChange={(_: any, newValue: ISelectOption) => {
                  if (!newValue?.value) {
                    dispatch(
                      updateFilter({
                        key: "idprofissional",
                        value: "",
                      })
                    );
                    setCurrentProfessionalFilter({
                      label: "",
                      value: "",
                    });
                  } else {
                    dispatch(
                      updateFilter({
                        key: "idprofissional",
                        value: newValue.value,
                      })
                    );
                    setCurrentProfessionalFilter(newValue);
                  }
                }}
                getOptionLabel={(option: { value: string; label: string }) =>
                  option ? option.label : ""
                }
                loading={isFetchingProfessionals}
                loadingText="Carregando..."
                noOptionsText="Nenhum profissional encontrado"
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    style={{ minWidth: 200 }}
                    variant="outlined"
                    {...params}
                    label="Profissional*"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                {...autocompleteDefaultProps}
                options={clinicsOptions as ISelectOption[]}
                getOptionSelected={(
                  option: ISelectOption,
                  value: ISelectOption
                ) => {
                  return value.value === option.value;
                }}
                value={clinicFilterValue}
                // @ts-ignore
                onChange={(_: any, newValue: ISelectOption) => {
                  if (!newValue?.value) {
                    dispatch(
                      updateFilter({
                        key: "idclinica",
                        value: "",
                      })
                    );
                    setClinicFilterValue(null);
                  } else {
                    dispatch(
                      updateFilter({
                        key: "idclinica",
                        value: newValue.value,
                      })
                    );
                    setClinicFilterValue(newValue);
                  }
                }}
                getOptionLabel={(option: { value: string; label: string }) =>
                  option.label
                }
                noOptionsText="Nenhuma clínica encontrada"
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    style={{ minWidth: 200 }}
                    variant="outlined"
                    {...params}
                    label="Clínica"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                {...autocompleteDefaultProps}
                options={[
                  { label: "Ordem de chegada", value: "check-in" },
                  { label: "Hora marcada", value: "agendamento" },
                ]}
                value={order}
                getOptionSelected={(
                  option: ISelectOption,
                  value: ISelectOption
                ) => {
                  return value.value === option.value;
                }}
                onChange={(_: any, newValue: ISelectOption | null) => {
                  if (!newValue?.value) {
                    setOrder({
                      label: "CHECK-IN",
                      value: "",
                    });
                    dispatch(
                      updateFilter({
                        key: "order",
                        value: "check-in",
                      })
                    );
                  } else {
                    setOrder({
                      label: newValue.label,
                      value: newValue.value,
                    });
                    dispatch(
                      updateFilter({
                        key: "order",
                        value: newValue.value,
                      })
                    );
                  }
                }}
                getOptionLabel={(option: { value: string; label: string }) =>
                  option.label
                }
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    style={{ minWidth: 200 }}
                    variant="outlined"
                    {...params}
                    label="Ordernar por"
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ height: "55px" }}
                onClick={() => setIsDateRangeDialogOpen(true)}
              >
                {`${dayjs(filterDate[0]).format("DD/MM/YYYY")} - ${dayjs(
                  filterDate[1]
                ).format("DD/MM/YYYY")}`}
              </Button>
            </Grid>

            {isDateRangeDialogOpen && (
              <Grid item>
                <DateRangeDialog
                  onChangeDate={onChangeDateRange}
                  handleClose={() => setIsDateRangeDialogOpen(false)}
                  open={isDateRangeDialogOpen}
                />
              </Grid>
            )}

            {/* BUSCAR */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ height: "55px" }}
                onClick={() => handleSearch()}
                disabled={!currentProfessionalFilter.value}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </TitleBar>
      </Grid>
      <Grid item xs={12}>
        <FullWidthContainer>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} justify="flex-end">
                <Grid item>
                  <Select
                    labelId="select-items-to-show"
                    value={limit}
                    onChange={(e: any) => {
                      dispatch(setLimit(e.target.value));
                      dispatch(setPage(1));
                    }}
                    label="Itens por página"
                    fullWidth
                  >
                    <MenuItem value={6}>Itens por página: 6</MenuItem>
                    <MenuItem value={20}>Itens por página: 20</MenuItem>
                    <MenuItem value={50}>Itens por página: 50</MenuItem>
                    <MenuItem value={100}>Itens por página: 100</MenuItem>
                    <MenuItem value={1000}>Itens por página: 1000</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isFetchingReleaseMedicalRecords ? (
                <Paper>
                  <Table>
                    <TableBody>
                      <SkeletonTable numberOfColumns={8} />
                    </TableBody>
                  </Table>
                </Paper>
              ) : releaseMedicalRecords.length === 0 ? (
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  Nenhum resultado encontrado (Verifique os filtros)
                </Typography>
              ) : (
                <ReleaseMedicalRecordsTable />
              )}
            </Grid>
          </Grid>
        </FullWidthContainer>
      </Grid>
    </Grid>
  ) : (
    <Loader></Loader>
  );
}
