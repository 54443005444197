import { formatCurrency } from "utils/formatCurrency";
import { getPercentage } from "utils/getPercentage";

function getServiceItemCount(services: any[], service_type: string) {
    const item = services.find((service) =>
      service.name.toLowerCase().includes(service_type.toLowerCase())
    );

    return item ? item.amount : 0;
}

function getServiceStatusCount(services: any[], service_status: string) {
    const item = services.find((service) =>
      service.name.toLowerCase().includes(service_status.toLowerCase())
    );

    return item ? item.amount : 0;
  }

function getServiceStatusAmountBill(services: any[], service_status: string) {
    const item = services.find((service) =>
      service.name.toLowerCase().includes(service_status.toLowerCase())
    );
    return item ? item.amount_bill_forecast : 0;
}

function getServiceItemValue(services: any[], service_type: string) {
  const item = services.find((service) => service.name.toLowerCase().includes(service_type.toLowerCase()));

  return item ? item.amount : "0";
}

export const dataXlsx = (data:any,table:any, encapsulation = true, total_amount_billed?:number, total_amount_profit?:number) => {
    const dataList = data.map((row: any) => {
        const objectRow: any = {};
        const typeMoneyRow: any = {};
        const typeDecimalRow: any = {};
        table.forEach((field:any, index: number) => {
          if(field.type && field.type === "money"){
            if(typeof row[field.key] === "number"){
              typeMoneyRow[field.label] = row[field.key].toFixed(2)
            }
          }
          if(field?.decimal){
            if(typeof row[field.key] === "number"){
              typeDecimalRow[field.label] = (row[field.key] * 100).toFixed(2)
            }
          }
          if("Margem" === field.label && "amount_profit" === field.key){
            return objectRow[field.label] = `${formatCurrency(row.amount_profit)} (${getPercentage(row.amount_profit, row.amount_billed)}%)`
         }
          if (["C", "E", "P", "R"].includes(field.label)) {
            return (objectRow[field.label] = `${getServiceItemCount(
              row.services_by_type_items,
              field.key
            )}  (${getPercentage(
              getServiceItemCount(
                row.services_by_type_items,
                field.key
              ),
              row.total_services_by_type_items
            )}%)`);
          }
  
          if (
            [
              "Agendado",
              "Confirmado",
              "Quitado",
              "Checkin",
              "Atendidos",
              "Faltosos",
              "Cancelados",
            ].includes(field.label)
          ) {
            return (objectRow[field.label] = `${getServiceStatusCount(
              row.services_by_status,
              field.key
            )}  (${getPercentage(
                    getServiceStatusCount(
                      row.services_by_status,
                      field.key
                    ),
                    row.total_services_by_status
                  )}%)`);
          }
          if (
            ["Fat Cancelados", "Fat Faltosos"].includes(
              field.label
            )
          ) {
            return (objectRow[field.label] = `${formatCurrency(getServiceStatusAmountBill(
              row.services_by_status,
              field.key
            ))}`);
          }
          if (
            ["laudos", "equipamentos", "solicitantes"].includes(
              field.key
            )
          ) {
            return objectRow[field.label] = `${formatCurrency(getServiceItemValue(row.services_by_type_items, field.key))}  (${getPercentage(
                getServiceItemCount(row.services_by_type_items, field.key),
                row.amount_billed
              )}%)`
           }

           if("Margem" === field.label && "amount_profit" === field.key){
              return objectRow[field.label] = `${formatCurrency(row.amount_profit)} (${getPercentage(row.amount_profit, row.amount_billed)}%)`
           }

           if("M x G" === field.label && "total_amount_profit" === field.key){
            return objectRow[field.label] =  total_amount_profit ? `${getPercentage(row.amount_profit, total_amount_profit)}%`: '-'
           }
           if("Fat x G" === field.label && "total_amount_billed" === field.key){
            return objectRow[field.label] = total_amount_billed ? `${getPercentage(row.amount_billed, total_amount_billed)}%` : '-'
           }
          return (objectRow[field.label] = row[field.key]);
        });
        
        return { ...objectRow, ...typeMoneyRow, ...typeDecimalRow};
      })
      if(!encapsulation) return dataList
      return [
        Object.keys(dataList[0]),
        ...dataList.map(Object.values),
      ];
}
